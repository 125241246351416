import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);


//contactPageContentIn
export default (nextDataWrapper) => {

    const heading = nextDataWrapper.querySelector('h1');
    new SplitText(heading, { type: "words, chars", wordsClass: 'words', charsClass: "chars" });
    const tl = gsap.timeline({})

        .from('.chars', {
            yPercent: 105,
            duration: 2,
            stagger: .025,
            ease: "power4.out"
        })
    return tl;
}