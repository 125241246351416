import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);



//darkThemeInContent
export default (nextBarbaWrapper) => {
    const heading = nextBarbaWrapper.querySelector('h1');
    const startImg = nextBarbaWrapper.querySelector('.img-col img');
    new SplitText(heading, { type: "words, chars", charsClass: "chars" });
    const chars = [...heading.querySelectorAll('.chars')];
    const tl = gsap.timeline({})
        .from(startImg, { scale: 1.10, duration: 2 }, "-=.5")
        .from(chars, {
            yPercent: 80,
            opacity: 0,
            duration: 2,
            stagger: .025,
            ease: "power4.out"
        }, "-=2.5")


    return tl;

}