import imagesLoaded from 'imagesloaded';
import Sniffer from 'sniffer';

import {
    MathUtils
} from '../utils/MathUtils';
import {
    gsap
} from 'gsap';

const config = {
    height: window.innerHeight,
    width: window.innerWidth
}

class ScrollJL {
    constructor() {
        this.data = {
            ease: 0.045,
            target: 0,
            current: 0,
            rounded: 0
        }

        this.dom = {
            el: document.querySelector('[data-scroll]'),
            content: document.querySelector('[data-scroll-content]')
        }



        // this.rAF = null

        this.init()
    }

    // bindMethods() {
    //     ['scroll', 'run', 'resize']
    //         .forEach((fn) => this[fn] = this[fn].bind(this))
    // }

    setStyles() {
        if (Sniffer.isDevice) return
        Object.assign(this.dom.el.style, {
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            overflow: 'hidden'
        })

    }

    setHeight() {
        document.body.style.height = `${this.dom.content.getBoundingClientRect().height}px`
    }

    resize = () => {
        this.setHeight()
        this.scroll()
    }

    preload() {
        imagesLoaded(this.dom.content, (instance) => {
            this.setHeight()
        })
    }

    scroll = () => {
        this.data.target = window.scrollY
    }

    run = () => {
        this.data.current = MathUtils.lerp(this.data.current, this.data.target, this.data.ease)
        this.data.rounded = Math.round(this.data.current * 100) / 100

        // const diff = this.data.target - this.data.rounded
        // const acc = diff / config.width
        // const velo = + acc
        // const skew = velo * 7.5

        // this.dom.content.style.transform = `translate3d(0, -${this.data.rounded}px, 0) skewY(${skew}deg)`;
        if (Sniffer.isDevice) return
        this.dom.content.style.transform = `translate3d(0, -${this.data.rounded}px, 0)`;
    }

    on() {



        this.setStyles()
        this.setHeight()
        this.addEvents()
        this.tick()
    }

    off() {

        this.untick()
        this.removeEvents()
    }

    tick() {
        // this.rAF = requestAnimationFrame(this.run)
        gsap.ticker.add(this.run)
    }

    untick() {
        gsap.ticker.remove(this.run)
    }

    destroy() {
        document.body.style.height = ''

        this.data = null

        this.removeEvents()
        this.untick()
    }

    resize() {
        this.setHeight()
        this.data.rounded = this.data.current = this.data.target
    }


    addEvents() {
        window.addEventListener('resize', this.resize, {
            passive: true
        })
        window.addEventListener('scroll', this.scroll, {
            passive: true
        })
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize, { passive: true })
        window.removeEventListener('scroll', this.scroll, { passive: true })
    }

    init() {
        this.preload()
        this.on()
    }
}

export {
    ScrollJL
}