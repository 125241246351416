import { gsap } from 'gsap';
import imagesLoaded from 'imagesloaded'



//preLoaderAnim
export default {
    in() {
        const tl = gsap.timeline({
            onComplete() {
                console.log('in is complete')
            }
        })

            .set('body', { backgroundColor: 'black' })
            .set('#imgMask1 rect', { scaleX: 0 })
            .set('#imgMask2 rect', { scaleX: 0 })
            .set('#canvas-container', {
                autoAlpha: 1,
            })
            .set('.blende-preLoader', {
                autoAlpha: 1,
            })
            .set('.blende-preLoader', {
                yPercent: 0,
            })
            .to('body', {
                opacity: 1,
                duration: 0.35
            })
            .set('body', { backgroundColor: 'white' })
        return tl
    },

    out() {
        const tl = gsap.timeline({
            onStart() {
                console.log('PreLoaderOutStarts')
            },
            // onComplete() {
            //     console.log('PreLoaderOutEnds')
            // }
        })

            .to('#imgMask1 rect', { scaleX: 1, duration: .75 })
            .to('#imgMask2 rect', { scaleX: 1, duration: .75 })
            .to('.blende-preLoader', {
                yPercent: -100,
                duration: 1.5,
                ease: "Power4.easeIn"
            })
            .set('#canvas-container', {
                autoAlpha: 0,
            })

        return tl;
    }

}