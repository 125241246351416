import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);


//homeInContent
export default () => {
    new SplitText("h1", { type: "words, chars", charsClass: "chars" });
    const tl = gsap.timeline({
        onStart() {
            console.log('Content In starts')
        },
        onComplete() {
            console.log('Content In ends')
        }
    })
        .from('h1', {
            scale: .5,
            opacity: .5,
            rotate: '-15deg',
            ease: 'Bounce.easeOut'
        })
    return tl;

}