import barba from '@barba/core';
import { gsap } from 'gsap';

import './services/font-service';
import './styles/main.scss';

import { Page } from './pages/Page'

// Boilerplate Components

import { Navi } from './ui-components/navi/Navi';
import { ScrollPreventer } from './ui-components/scrollPreventer/scrollPreventer';


// Animations
import {
    awaitAllImages, blendeInAnimation, blendeOutAnimation,
    preLoaderAnim, aboutPageContentIn, homePageContentIn,
    galleryPageContentIn, contactPageContentIn
} from './animations/animations';

let pageComponents = { homePage: undefined, aboutPage: undefined, galleryPage: undefined, contactPage: undefined };



let pageInAnimations = { aboutPage: aboutPageContentIn, homePage: homePageContentIn, galleryPage: galleryPageContentIn, contactPage: contactPageContentIn }

// Init Boilerplate Components
// const navi = new Navi();
let scrollPreventer = new ScrollPreventer();

const createPageComponent = (nextData) => {
    pageComponents[nextData.namespace] = new Page(nextData)
}
const destroyPageComponent = (currentData) => {
    scrollPreventer.disableScroll()
    pageComponents[currentData.namespace].destroy();
    pageComponents[currentData.namespace] = undefined;
}


const enterTransition = async (data) => {
    await awaitAllImages(data)

    window.scrollTo(0, 0)
    const tl = gsap.timeline({
        onComplete() {
            pageComponents[data.next.namespace].refreshSmoothScrollItemsPositions();
            pageComponents[data.next.namespace] && pageComponents[data.next.namespace].smoothScroll.on()
            scrollPreventer.enableScroll()
        }
    })
    if (navi.naviOpen) { tl.add(navi.closeNavi()) }
    if (!navi.naviOpen) { tl.add(blendeOutAnimation(data)) }
    tl.add(pageInAnimations[data.next.namespace](data.next.container))
}



barba.init({
    debug: true,
    views: [{
        namespace: 'homePage',
        beforeEnter(data) { createPageComponent(data.next) },
        beforeLeave(data) { destroyPageComponent(data.current) }
    },

    ],

    transitions: [{
        name: 'homePage-transition',
        to: { namespace: ['homePage'] },
        once() { },
        async leave(data) {
            if (!navi.naviOpen) await blendeInAnimation('#252525');
        },
        async enter(data) {
            await enterTransition(data)
        },
    },

    ]
});





// Global Hook is working for 
// after Enter
// once  
// It is not working for beforeEnter, Enter 



// barbaHooks ONCE Preloader & co
barba.hooks.once(async (data) => {
    console.log('once')

    await preLoaderAnim.in();
    await awaitAllImages(data);
    const namespaceName = data.next.namespace;
    const masterTL = gsap.timeline({
        onComplete() {
            pageComponents[namespaceName] && pageComponents[namespaceName].smoothScroll.on()
            scrollPreventer.enableScroll()
        }
    })
        .add(preLoaderAnim.out())
        .add(pageInAnimations[data.next.namespace](data.next.container))

})

barba.hooks.afterEnter(() => {
    console.log('afterEnter')
})