import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);


//aboutPageContentIn
export default (nextDataWrapper) => {

    const heading = nextDataWrapper.querySelector('h1');
    new SplitText(heading, { type: "lines", linesClass: "line" });
    new SplitText(heading, { type: "lines", linesClass: "outer-line" });
    const lines = [...heading.querySelectorAll('.line')];
    const startImg = nextDataWrapper.querySelector('#startImg')

    const tl = gsap.timeline({})

        .from(lines, {
            yPercent: 105,
            duration: 2,
            stagger: .25,
            ease: "power4.out"
        })
        .from(startImg, {
            yPercent: 10,
            opacity: 0,
            ease: "power4.out",
            duration: 2,
        }, '-=1.5')

    return tl;

}