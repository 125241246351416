import { gsap } from 'gsap';


//blendeUp
export default (blendColor) => {
    const tl = gsap.timeline()

        .set('#canvas-container .blende', {
            backgroundColor: blendColor,
            yPercent: 100,
            visibility: 'visible'
        })
        .set('#canvas-container', {
            autoAlpha: 1,
        })
        .to('.blende', {
            yPercent: 0,
        })
    // .set('#canvas-container .blende', {
    //     backgroundColor: ''
    // })
    return tl
}

