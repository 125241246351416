import { gsap } from 'gsap';


//blende Out
export default ({ next }) => {


    const tl = gsap.timeline({
        // onStart() {
        //     console.log('blende Out starts')
        // },
        // onComplete() {
        //     console.log('blende Out ends')
        // }
    })
        .to('#canvas-container .blende', {
            yPercent: -100,
            duration: .75
        })
        .set('#canvas-container', {
            autoAlpha: 0,
        })
        .set('#canvas-container .blende', {
            yPercent: 0, backgroundColor: '', visibility: 'hidden'
        })

    return tl;
}